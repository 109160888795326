import { ref, watch, computed } from "@vue/composition-api";
import axios from "axios";
export default function () {
  const statusTableRef = ref(null);
  const isLoadingStatuses = ref(false);
  const statusTableFields = [
    { key: "name", label: "Name" },
    { key: "color", label: "Color" },
    { key: "status", label: "Status" },
    { key: "actions", label: "Actions" },
  ];

  const statusColors = [
    { value: "primary", text: "Primary" },
    { value: "secondary", text: "Secondary" },
    { value: "success", text: "Success" },
    { value: "danger", text: "Danger" },
    { value: "warning", text: "Warning" },
    { value: "info", text: "Info" },
  ];

  const statusStatuses = [
    { value: true, text: "Active" },
    { value: false, text: "Disabled" },
  ];

  const getAllStatuses = async () => {
    try {
      isLoadingStatuses.value = true;
      const { data } = await axios.get("/support/status");
      return data.data;
    } catch (e) {
      return [];
    } finally {
      isLoadingStatuses.value = false;
    }
  };

  return {
    statusTableRef,
    statusTableFields,
    getAllStatuses,
    statusColors,
    statusStatuses,
    isLoadingStatuses,
  };
}
