<template>
  <div id="ticketSettingsStatuses">
    <h4>General Settings</h4>
    <b-row>
      <b-col cols="4" class="mx-auto">
        <div v-if="message" class="alert alert-danger p-1">{{ message }}</div>
        <div v-if="success" class="alert alert-success p-1">{{ success }}</div>
        <b-form-group label="Ticket Open Status">
          <b-form-select
            :options="statuses"
            :disabled="isSaving || isLoading"
            v-model="openStatus"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Ticket Close Status">
          <b-form-select
            :options="statuses"
            :disabled="isSaving || isLoading"
            v-model="closeStatus"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <b-button
            :disabled="!openStatus || !closeStatus || isSaving || isLoading"
            @click="saveSettings"
            variant="primary"
            block
            >{{ isSaving ? "Saving..." : "Save Settings" }}</b-button
          >
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import axios from "axios";

export default {
  name: "ticketSettingsStatuses",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      statuses: [],
      message: "",
      success: "",
      closeStatus: "",
      openStatus: "",
    };
  },

  methods: {
    getAllStatuses: async function () {
      try {
        this.isLoading = true;
        const { data } = await axios.get("/support/status");
        this.statuses = data.data.map((x) => {
          return { value: x._id, text: x.name };
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    getSettings: async function () {
      try {
        this.isLoading = true;
        const { data } = await axios.get("/support/settings");
        var { ticketClosedStatusId, ticketOpenStatusId } = data.data;
        this.openStatus = ticketOpenStatusId;
        this.closeStatus = ticketClosedStatusId;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    saveSettings: async function () {
      try {
        this.isSaving = true;
        const formData = new FormData();
        formData.append("ticketOpenStatusId", this.openStatus);
        formData.append("ticketClosedStatusId", this.closeStatus);
        const { data } = await axios.post("/support/settings", formData);
        this.success = "Settings Saved Successfully";
      } catch (e) {
        this.message = "Unable to save : " + e.message;
      } finally {
        this.isSaving = false;
      }
    },
  },
  mounted() {
    /// Get all ticket statuses
    this.getAllStatuses();
    // get the setting values
    this.getSettings();
  },
};
</script>
