<template>
  <div id="SupportSettings">
    <b-card no-body>
      <b-card-header class="border-bottom">
        <h3 class="mb-0">Settings</h3>
      </b-card-header>
      <b-card-body class="p-0">
        <b-tabs>
          <b-tab active title="Tickets Statuses" lazy>
            <settings-statuses />
          </b-tab>
          <b-tab title="General Settings" lazy>
            <settings-general />
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardHeader, BCardBody, BCol, BRow, BTabs, BTab } from "bootstrap-vue";
import SettingsGeneral from "./SettingsGeneral.vue";
import SettingsStatuses from "./SettingsStatuses.vue";
export default {
  name: "SupportSettings",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BTabs,
    BTab,
    SettingsGeneral,
    SettingsStatuses,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style>
#SupportSettings .nav-tabs {
  margin-bottom: 0px;
  border-bottom: 1px solid #ece9f1;
}
#SupportSettings .nav-tabs .nav-item {
  border-right: 1px solid #ece9f1;
}
#SupportSettings .nav-tabs .nav-item .nav-link {
  padding: 1rem 1.5rem;
}
#SupportSettings .nav-tabs .nav-item .nav-link.active {
  background-color: #f1f1f1;
  color: #000;
}
#SupportSettings .tab-content {
  padding: 20px;
}
</style>
