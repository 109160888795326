<template>
  <div id="ticketSettingsStatuses">
    <h4>Ticket Statuses</h4>
    <b-row>
      <b-col cols="7">
        <b-card no-body>
          <b-card-header class="p-1">
            <h5 class="mb-0">All Card Statuses</h5>
          </b-card-header>
          <b-table
            :tbody-tr-class="trClass"
            :fields="statusTableFields"
            :items="getAllStatuses"
            ref="statusTableRef"
            show-empty
            :empty-html="
              isLoadingStatuses == true
                ? `<div class='text-center'><span class='fa fa-spinner fa-spin'></span> Loading...</div>`
                : `Sorry! No statuses found.`
            "
          >
            <template #cell(color)="row">
              <div class="d-flex align-items-center">
                <div :class="['bullet', 'bullet-sm', `bullet-${row.item.color}`]"></div>
                <div class="ml-1">{{ row.item.color }}</div>
              </div>
            </template>
            <template #cell(status)="row">
              <b-badge :variant="row.item.status ? 'success' : 'danger'">{{
                row.item.status ? "Active" : "Disabled"
              }}</b-badge>
            </template>
            <template #cell(actions)="row">
              <b-button
                :disabled="mode == 'update' && row.item._id == id"
                variant="warning"
                @click="editStatus(row.item)"
                size="sm"
                >Edit</b-button
              >
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col cols="5">
        <b-card no-body>
          <b-card-header class="p-1">
            <h5 class="mb-0">{{ mode == "add" ? "Add New Status" : "Update Status" }}</h5>
          </b-card-header>
          <b-card-body>
            <div v-if="message" :class="['alert', `alert-${message.type}`, 'p-1']">
              {{ message.message }}
            </div>
            <b-form-group label="Name">
              <b-form-input v-model="name" />
            </b-form-group>
            <b-row>
              <b-col cols="6"
                ><b-form-group label="Color">
                  <b-form-select :options="statusColors" v-model="color" /> </b-form-group
              ></b-col>
              <b-col cols="6"
                ><b-form-group label="Status">
                  <b-form-select :options="statusStatuses" v-model="status" /></b-form-group
              ></b-col>
            </b-row>

            <b-row>
              <b-col :cols="mode === 'add' ? 12 : 6">
                <b-button
                  @click="mode == 'add' ? addNew() : update()"
                  :disabled="isSaving || !name || !status || !color"
                  block
                  variant="primary"
                  >{{ isSaving ? "Please wait" : mode == "add" ? "Save" : "Update" }}</b-button
                ></b-col
              >
              <b-col v-if="mode == 'update'" cols="6">
                <b-button @click="cancelEditing" variant="secondary" block>Cancel</b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BTable,
  BBadge,
} from "bootstrap-vue";
import axios from "axios";
import useSettings from "./useSettings";
export default {
  name: "ticketSettingsStatuses",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BTable,
    BBadge,
  },
  data() {
    return {
      mode: "add",
      name: "",
      color: "",
      status: true,
      id: "",
      isSaving: false,
      message: null,
    };
  },
  methods: {
    trClass: function (item, type) {
      if (type == "row" && this.mode == "update" && this.id == item._id) {
        return "bg-light-secondary";
      }
    },
    editStatus: function (row) {
      this.name = row.name;
      this.status = row.status;
      this.color = row.color;
      this.id = row._id;
      this.mode = "update";
    },
    cancelEditing: function () {
      this.name = "";
      this.status = true;
      this.color = "";
      this.id = "";
      this.mode = "add";
    },
    addNew: async function () {
      try {
        this.isSaving = true;

        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("status", this.status);
        formData.append("color", this.color);
        const { data } = await axios.post("/support/status", formData);
        this.message = {
          type: "success",
          message: data.message,
        };
        this.statusTableRef.refresh();
        this.name = "";
        this.status = true;
        this.color = "";
      } catch (e) {
        this.message = {
          type: "danger",
          message: e.response.data.error.message || e.message,
        };
      } finally {
        this.isSaving = false;
      }
    },
    update: async function () {
      try {
        this.isSaving = true;

        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("status", this.status);
        formData.append("color", this.color);
        const { data } = await axios.put(`/support/status/${this.id}`, formData);
        this.statusTableRef.refresh();
        this.mode = "add";
        this.name = "";
        this.status = true;
        this.color = "";
        this.message = {
          type: "success",
          message: data.message,
        };
      } catch (e) {
        this.message = {
          type: "danger",
          message: e.response.data.error.message || e.message,
        };
      } finally {
        this.isSaving = false;
      }
    },
  },
  setup() {
    const {
      statusTableRef,
      statusTableFields,
      getAllStatuses,
      statusStatuses,
      statusColors,
      isLoadingStatuses,
    } = useSettings();

    return {
      statusTableRef,
      statusTableFields,
      getAllStatuses,
      statusStatuses,
      statusColors,
      isLoadingStatuses,
    };
  },
};
</script>
